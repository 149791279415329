<template>
  <b-card>
    <b-card-title class="mb-1">
      {{ $t('Counselor statistics') }}
    </b-card-title>

    <vue-apex-charts type="donut" height="350" :options="chartOptions" :series="series" />
  </b-card>
</template>

<script>
import {
  BCard, BCardTitle,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'

const color = {
  series1: '#ffe700',
  series2: '#00d4bd',
  series3: '#826bf8',
  series4: '#2b9bf4',
  series5: '#FFA1A1',
}
export default {
  components: {
    VueApexCharts,
    BCard,
    BCardTitle,
  },
  props: {
    statistic: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      series: [],
      label: [],
    }
  },
  computed: {
    chartOptions: {
      get() {
        return {
          legend: {
            show: true,
            position: 'bottom',
            fontSize: '14px',
            fontFamily: 'Montserrat',
          },
          colors: [
            color.series1,
            color.series2,
            color.series3,
            color.series4,
            color.series5,
          ],
          dataLabels: {
            enabled: true,
            formatter(val) {
              // eslint-disable-next-line radix
              return `${parseFloat(val).toFixed(2)}%`
            },
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    fontSize: '2rem',
                    // fontFamily: 'Montserrat',
                  },
                  value: {
                    fontSize: '1rem',
                    // fontFamily: 'Montserrat',
                    formatter(val) {
                      // eslint-disable-next-line radix
                      return `${val}%`
                    },
                  },
                  total: {
                    show: false,
                    fontSize: '1.5rem',
                    label: 'Operational',
                    formatter() {
                      return '31%'
                    },
                  },
                },
              },
            },
          },
          labels: [],
          responsive: [
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 380,
                },
                legend: {
                  position: 'bottom',
                },
              },
            },
            {
              breakpoint: 576,
              options: {
                chart: {
                  height: 320,
                },
                plotOptions: {
                  pie: {
                    donut: {
                      labels: {
                        show: true,
                        name: {
                          fontSize: '1.5rem',
                        },
                        value: {
                          fontSize: '1rem',
                        },
                        total: {
                          fontSize: '1.5rem',
                        },
                      },
                    },
                  },
                },
                legend: {
                  show: true,
                },
              },
            },
          ],
        }
      },
      set(v) {
        console.log(v)
      },
    },
  },

  watch: {
    statistic(val) {
      if (val) {
        if (val.length > 0) {
          this.series = val.map(e => e.count)
          Object.assign(this.chartOptions.labels, val.map(e => (this.showFromCurrentLanguage(e.serviceName))))
        }
      }
    },
  },
  methods: {
    showFromCurrentLanguage(data) {
      const indexLang = data.findIndex(e => e.lang === this.$i18n.locale)
      if (indexLang > -1) {
        return data[indexLang].value
      }
      return ''
    },
  },
}
</script>
