<template>
  <b-card :title="$t('Gender, age when coming to use the service')">
    <!-- chart -->
    <chartjs-component-doughnut-chart :height="275" :data="series" :options="option"
      class="mb-3" />
    <!--/ chart -->

    <!-- stocks -->
    <div v-for="(stock, index) in stockData" :key="stock.device" :class="index < stockData.length - 1 ? 'mb-1' : ''"
      class="d-flex justify-content-between">
      <div class="d-flex align-items-center">
        <feather-icon :icon="stock.symbol" size="16" :style="`color: ${stock.color};`" />
        <span class="font-weight-bold ml-75 mr-25">{{ stock.name }}</span>
      </div>
      <div>
        <span>{{ stock.percentage }}%</span>

      </div>
    </div>
    <!--/ stocks -->
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import ChartjsComponentDoughnutChart from './charts-components/ChartjsComponentDoughnutChart.vue'
import chartjsData from './chartjsData'
import { $themeColors } from '@themeConfig'
// colors
const chartColors = {
  primaryColorShade: '#836AF9',
  yellowColor: '#ffe800',
  successColorShade: '#28dac6',
  warningColorShade: '#ffe802',
  warningLightColor: '#FDAC34',
  infoColorShade: '#299AFF',
  greyColor: '#4F5D70',
  blueColor: '#2c9aff',
  blueLightColor: '#84D0FF',
  greyLightColor: '#EDF1F4',
  tooltipShadow: 'rgba(0, 0, 0, 0.25)',
  lineChartPrimary: '#666ee8',
  lineChartDanger: '#ff4961',
  labelColor: '#6e6b7b',
  grid_line_color: 'rgba(200, 200, 200, 0.2)',
}
export default {
  components: {
    ChartjsComponentDoughnutChart,
    BCard,
  },
  data() {
    return {
      chartjsData,
    }
  },
  computed: {
    series() {
      return {
        datasets: [
          {
            labels: [this.$t('Male'), this.$t('Female')],
            data: [55, 45],
            backgroundColor: ['#8744E1', '#FE718E'],
            borderWidth: 0,
            pointStyle: 'rectRounded',
          },
        ],
      }
    },
    option() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        responsiveAnimationDuration: 500,
        cutoutPercentage: 60,
        legend: { display: false },
        tooltips: {
          callbacks: {
            label(tooltipItem, data) {
              const label = data.datasets[0].labels[tooltipItem.index] || ''
              const value = data.datasets[0].data[tooltipItem.index]
              const output = ` ${label} : ${value} %`
              return output
            },
          },
          // Updated default tooltip UI
          shadowOffsetX: 1,
          shadowOffsetY: 1,
          shadowBlur: 8,
          shadowColor: chartColors.tooltipShadow,
          backgroundColor: $themeColors.light,
          titleFontColor: $themeColors.dark,
          bodyFontColor: $themeColors.dark,
        },
      }
    },
    stockData() {
      return [
        {
          name: this.$t('Male'), symbol: 'MonitorIcon', color: '#8744E1', percentage: 55, upDown: 0,
        },
        {
          name: this.$t('Female'), symbol: 'TabletIcon', color: '#FE718E', percentage: 45, upDown: 0,
        },
      ]
    },
  },
}
</script>
