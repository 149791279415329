<template>
  <b-card no-body>
    <b-card-header>
      <!-- title and legend -->
      <b-card-title class="mb-50">
        {{ $t('Age') }}
      </b-card-title>
      <!--/ title and legend -->

      <!-- datepicker -->
      <!-- <div class="d-flex align-items-center">
        <feather-icon
          icon="CalendarIcon"
          size="16"
        />
        <flat-pickr
          v-model="rangePicker"
          :config="{ mode: 'range'}"
          class="form-control flat-picker bg-transparent border-0 shadow-none"
          placeholder="YYYY-MM-DD"
        />
      </div> -->
      <!-- datepicker -->
    </b-card-header>

    <b-card-body>
      <vue-apex-charts type="bar" height="400" :options="options" :series="series" />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BCardHeader, BCardTitle,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
// import flatPickr from 'vue-flatpickr-component'
import apexChatData from './apexChartData'

export default {
  components: {
    BCard,
    BCardBody,
    BCardHeader,
    VueApexCharts,
    // flatPickr,
    BCardTitle,
  },
  data() {
    return {
      apexChatData,
      rangePicker: ['2019-05-01', '2019-05-10'],

    }
  },
  computed: {
    series() {
      return [
        {
          name: this.$t('Male'),
          data: [90, 120, 55, 100, 80, 125, 175, 70, 88, 180],
        },
        {
          name: this.$t('Female'),
          data: [85, 100, 30, 40, 95, 90, 30, 110, 62, 20],
        },
      ]
    },
    options() {
      return {
        chart: {
          stacked: false,
          toolbar: {
            show: false,
          },
        },
        colors: ['#5CCFFE', '#8744E1'],
        plotOptions: {
          bar: {
            // columnWidth: '15%',
            colors: {
              backgroundBarColors: [
                // chartColors.column.bg,
                // chartColors.column.bg,
                // chartColors.column.bg,
                // chartColors.column.bg,
              ],
              backgroundBarRadius: 20,
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: true,
          position: 'top',
          fontSize: '14px',
          fontFamily: 'Montserrat',
          horizontalAlign: 'left',
        },
        stroke: {
          show: true,
          colors: ['transparent'],
        },
        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
        },
        xaxis: {
          categories: ['17-20', '21-25', '26-30', '31-35', '36-40', '41-45', '50+'],
        },
        yaxis: {
          // opposite: isRtl,
        },
        fill: {
          opacity: 1,
        },
      }
    },
  },
}
</script>
