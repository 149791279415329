import axios from "@axios";

export default {
  namespaced: true,
  state: {
    respData: {
      allUsers: 0,
      allPartnerUsers: 0,
      allListStaff: 0,
      allBooking: 0,
      allSuccessQueue: 0,
      allWaitingQueue: 0,
      counselor_statistics: [],
    },
  },
  getters: {},
  mutations: {
    GET(state, payload) {
      state.respData = payload;
    },
  },
  actions: {
    get({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/api/web/application/hero/dashboard/get?start=${item.start}&end=${item.end}&agencyId=${item.agencyId}&departmentId=${item.departmentId}`
          )
          .then((response) => {
            commit("GET", response.data.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
