<template>
  <b-card no-body>
    <b-card-header>
      <div>
        <b-card-title class="mb-1">
          {{ $t('Statistics comparing the number of follow-up appointments and referrals') }}
        </b-card-title>
      </div>
    </b-card-header>

    <!-- chart -->
    <b-card-body>
      <chartjs-component-line-chart :height="400" :data="series" :options="options" :plugins="plugins" />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCardTitle,
} from 'bootstrap-vue'
import { $themeColors } from '@themeConfig'
import ChartjsComponentLineChart from './charts-components/ChartjsComponentLineChart.vue'
import chartjsData from './chartjsData'
// colors
const chartColors = {
  primaryColorShade: '#836AF9',
  yellowColor: '#ffe800',
  successColorShade: '#28dac6',
  warningColorShade: '#ffe802',
  warningLightColor: '#FDAC34',
  infoColorShade: '#299AFF',
  greyColor: '#4F5D70',
  blueColor: '#2c9aff',
  blueLightColor: '#84D0FF',
  greyLightColor: '#EDF1F4',
  tooltipShadow: 'rgba(0, 0, 0, 0.25)',
  lineChartPrimary: '#666ee8',
  lineChartDanger: '#ff4961',
  labelColor: '#6e6b7b',
  grid_line_color: 'rgba(200, 200, 200, 0.2)',
}
export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    ChartjsComponentLineChart,
  },
  data() {
    return {
      chartjsData,
      plugins: [
        // to add spacing between legends and chart
        {
          beforeInit(chart) {
            /* eslint-disable func-names, no-param-reassign */
            chart.legend.afterFit = function () {
              this.height += 20
            }
            /* eslint-enable */
          },
        },
      ],
    }
  },
  computed: {
    series() {
      return {
        labels: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140],
        datasets: [
          {
            data: [80, 150, 180, 270, 210, 160, 160, 202, 265, 210, 270, 255, 290, 360, 375],
            label: this.$t('Psychiatrist'),
            borderColor: '#FF5660',
            lineTension: 0.5,
            pointStyle: 'circle',
            backgroundColor: '#FF5660',
            fill: false,
            pointRadius: 1,
            pointHoverRadius: 5,
            pointHoverBorderWidth: 5,
            pointBorderColor: 'transparent',
            pointHoverBorderColor: $themeColors.white,
            pointHoverBackgroundColor: '#FF5660',
            pointShadowOffsetX: 1,
            pointShadowOffsetY: 1,
            pointShadowBlur: 5,
            pointShadowColor: chartColors.tooltipShadow,
          },
          {
            data: [80, 125, 105, 130, 215, 195, 140, 160, 230, 300, 220, 170, 210, 200, 280],
            label: this.$t('Psychologist'),
            borderColor: '#FFC400',
            lineTension: 0.5,
            pointStyle: 'circle',
            backgroundColor: '#FFC400',
            fill: false,
            pointRadius: 1,
            pointHoverRadius: 5,
            pointHoverBorderWidth: 5,
            pointBorderColor: 'transparent',
            pointHoverBorderColor: $themeColors.white,
            pointHoverBackgroundColor: '#FFC400',
            pointShadowOffsetX: 1,
            pointShadowOffsetY: 1,
            pointShadowBlur: 5,
            pointShadowColor: chartColors.tooltipShadow,
          },
          {
            data: [80, 99, 82, 90, 115, 115, 74, 75, 130, 155, 125, 90, 140, 130, 180],
            label: this.$t('Counselor'),
            borderColor: '#24CCB8',
            lineTension: 0.5,
            pointStyle: 'circle',
            backgroundColor: '#24CCB8',
            fill: false,
            pointRadius: 1,
            pointHoverRadius: 5,
            pointHoverBorderWidth: 5,
            pointBorderColor: 'transparent',
            pointHoverBorderColor: $themeColors.white,
            pointHoverBackgroundColor: '#24CCB8',
            pointShadowOffsetX: 1,
            pointShadowOffsetY: 1,
            pointShadowBlur: 5,
            pointShadowColor: chartColors.tooltipShadow,
          },
          {
            data: [201, 112, 45, 289, 143, 56, 198, 274, 107, 23, 266, 32, 155, 73, 219, 4],
            label: this.$t('Lecturer'),
            borderColor: '#4880FF',
            lineTension: 0.5,
            pointStyle: 'circle',
            backgroundColor: '#4880FF',
            fill: false,
            pointRadius: 1,
            pointHoverRadius: 5,
            pointHoverBorderWidth: 5,
            pointBorderColor: 'transparent',
            pointHoverBorderColor: $themeColors.white,
            pointHoverBackgroundColor: '#4880FF',
            pointShadowOffsetX: 1,
            pointShadowOffsetY: 1,
            pointShadowBlur: 5,
            pointShadowColor: chartColors.tooltipShadow,
          }, {
            data: [80, 99, 82, 90, 115, 115, 74, 75, 130, 155, 125, 90, 140, 130, 180],
            label: this.$t('Activist'),
            borderColor: '#EB5EBD',
            lineTension: 0.5,
            pointStyle: 'circle',
            backgroundColor: '#EB5EBD',
            fill: false,
            pointRadius: 1,
            pointHoverRadius: 5,
            pointHoverBorderWidth: 5,
            pointBorderColor: 'transparent',
            pointHoverBorderColor: $themeColors.white,
            pointHoverBackgroundColor: '#EB5EBD',
            pointShadowOffsetX: 1,
            pointShadowOffsetY: 1,
            pointShadowBlur: 5,
            pointShadowColor: chartColors.tooltipShadow,
          }, {
            data: [129, 41, 203, 54, 88, 277, 172, 98, 210, 6, 284, 155, 267, 119, 78, 291],
            label: this.$t('Class'),
            borderColor: '#88ACFF',
            lineTension: 0.5,
            pointStyle: 'circle',
            backgroundColor: '#88ACFF',
            fill: false,
            pointRadius: 1,
            pointHoverRadius: 5,
            pointHoverBorderWidth: 5,
            pointBorderColor: 'transparent',
            pointHoverBorderColor: $themeColors.white,
            pointHoverBackgroundColor: '#88ACFF',
            pointShadowOffsetX: 1,
            pointShadowOffsetY: 1,
            pointShadowBlur: 5,
            pointShadowColor: chartColors.tooltipShadow,
          }, {
            data: [73, 245, 158, 17, 94, 289, 136, 206, 41, 119, 266, 29, 182, 255, 102, 7],
            label: this.$t('Another'),
            borderColor: '#0C44C1',
            lineTension: 0.5,
            pointStyle: 'circle',
            backgroundColor: '#0C44C1',
            fill: false,
            pointRadius: 1,
            pointHoverRadius: 5,
            pointHoverBorderWidth: 5,
            pointBorderColor: 'transparent',
            pointHoverBorderColor: $themeColors.white,
            pointHoverBackgroundColor: '#0C44C1',
            pointShadowOffsetX: 1,
            pointShadowOffsetY: 1,
            pointShadowBlur: 5,
            pointShadowColor: chartColors.tooltipShadow,
          },
        ],
      }
    },
    options() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        backgroundColor: false,
        hover: {
          mode: 'label',
        },
        tooltips: {
          // Updated default tooltip UI
          shadowOffsetX: 1,
          shadowOffsetY: 1,
          shadowBlur: 8,
          shadowColor: chartColors.tooltipShadow,
          backgroundColor: $themeColors.white,
          titleFontColor: $themeColors.black,
          bodyFontColor: $themeColors.black,
        },
        layout: {
          padding: {
            top: -15,
            bottom: -25,
            left: -15,
          },
        },
        scales: {
          xAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
              },
              gridLines: {
                display: true,
                color: chartColors.grid_line_color,
                zeroLineColor: chartColors.grid_line_color,
              },
              ticks: {
                fontColor: chartColors.labelColor,
              },
            },
          ],
          yAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
              },
              ticks: {
                stepSize: 100,
                min: 0,
                max: 400,
                fontColor: chartColors.labelColor,
              },
              gridLines: {
                display: true,
                color: chartColors.grid_line_color,
                zeroLineColor: chartColors.grid_line_color,
              },
            },
          ],
        },
        legend: {
          position: 'top',
          align: 'start',
          labels: {
            usePointStyle: true,
            padding: 25,
            boxWidth: 9,
          },
        },
      }
    },
  },
}
</script>
