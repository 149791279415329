<template>
  <div>
    <b-row class="match-height">
      <b-col xl="4" md="4" sm="12">
        <b-row>
          <b-col xl="2">
            <span class="">ช่วงเวลา</span>
          </b-col>
          <b-col xl="5">
            <flat-pickr v-model="start" @input="getDashboard()" class="form-control" :config="configs" />
          </b-col>
          <b-col xl="5">
            <flat-pickr v-model="end" class="form-control" @input="getDashboard()" :config="configs" />
          </b-col>
        </b-row>

      </b-col>
      <b-col xl="4" md="4" sm="12">
        <b-row>
          <b-col xl="2">
            <span class="">{{ $t('Select') }} {{ $t('Agency') }}</span>
          </b-col>
          <b-col xl="10">
            <v-select v-model="agencyId" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="name"
              @input="getDashboard()" :options="agencyData" :reduce="(agencyData) => agencyData._id"
              class="select-size" />
          </b-col>
        </b-row>

      </b-col>
      <b-col xl="4" md="4" sm="12">
        <b-row>
          <b-col xl="2">
            <span class="">{{ $t('Select') }} {{ $t('Department') }}</span>
          </b-col>
          <b-col xl="10">
            <v-select v-model="departmentId" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="name"
              @input="getDashboard()" :options="departmentData" :reduce="(departmentData) => departmentData._id"
              class="select-size" />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row class="match-height mt-2">
      <b-col xl="3" md="4" sm="6">
        <statistic-card-vertical color="danger" icon="HomeIcon" :statistic="respData.allUsers"
          :statistic-title="$t('Total number of users')" />
      </b-col>
      <b-col xl="3" md="4" sm="6">
        <statistic-card-vertical color="primary" icon="EditIcon" :statistic="respData.allPartnerUsers"
          :statistic-title="$t('Total number of service recipients')" />
      </b-col>
      <b-col xl="3" md="4" sm="6">
        <statistic-card-vertical color="warning" icon="UsersIcon" :statistic="respData.allListStaff"
          :statistic-title="$t('Number of psychologists, psychiatrists')" />
      </b-col>
      <b-col xl="1" md="4" sm="6">
        <statistic-card-vertical color="primary" icon="FileTextIcon" :statistic="respData.allBooking"
          :statistic-title="$t('All appointments')" />
      </b-col>
      <b-col xl="1" md="4" sm="6">
        <statistic-card-vertical color="success" icon="CheckCircleIcon" :statistic="respData.allSuccessQueue"
          :statistic-title="$t('Service now available')" />
      </b-col>
      <b-col xl="1" md="4" sm="6">
        <statistic-card-vertical color="warning" icon="ClockIcon" :statistic="respData.allWaitingQueue"
          :statistic-title="$t('Waiting to serve')" />
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col md="7">
        <apex-heat-map-chart />
      </b-col>
      <b-col md="5">
        <apex-donut-chart :statistic="respData.counselor_statistics" />
      </b-col>
      <!-- <b-col cols="7">
        <b-card>
          <b-row>
            <b-col cols="4">
              <ChartjsDoughnutChart />
            </b-col>
            <b-col cols="8">
              <apex-data-bar-chart />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col cols="5">
        <b-card />
      </b-col>
      <b-col cols="12">
        <ChartjsLineChart />
      </b-col>
      <b-col cols="12">
        <b-card />
      </b-col>
      <b-col cols="12">
        <b-card />
      </b-col> -->
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol, BCard } from 'bootstrap-vue'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
// import ApexLineAreaChart from './components/ApexLineAreaChart.vue'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import store from '@/store'
// eslint-disable-next-line import/extensions
import { Thai } from 'flatpickr/dist/l10n/th.js'
import storeModule from '@/store/services/hero/dashboard'
import storeModuleDepartment from '@/store/services/hero/department'
import storeModuleAgency from '@/store/services/hero/agency'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ApexDataBarChart from './components/ApexDataBarChart.vue'
import ApexHeatMapChart from './components/ApexHeatMapChart.vue'
import ApexDonutChart from './components/ApexDonutChart.vue'
import ChartjsLineChart from './components/ChartjsLineChart.vue'
import ChartjsDoughnutChart from './components/ChartjsDoughnutChart.vue'

const STORE_MODULE_NAME = 'dashboard'
const STORE_MODULE_NAME_DEPARTMENT = 'department'
const STORE_MODULE_NAME_AGENCY = 'agency'

export default {
  components: {
    StatisticCardVertical,
    BRow,
    BCol,
    BCard,
    // ApexLineAreaChart,
    ApexDataBarChart,
    ApexHeatMapChart,
    ApexDonutChart,
    ChartjsLineChart,
    ChartjsDoughnutChart,
    flatPickr,
    vSelect,
  },
  data() {
    return {
      memberOnline: 0,
      franchiseOnline: 0,
      rootOnline: 0,
      agencyId: '',
      departmentId: '',
      start: this.formatDate(new Date(Date.now())),
      end: this.formatDate(new Date(new Date().setMonth(new Date().getMonth() + 1))),
      configs: {
        enableTime: false,
        dateFormat: 'd-m-Y',
        locale: Thai,
      },
    }
  },
  computed: {
    respData() {
      return store.state[STORE_MODULE_NAME].respData != null
        ? store.state[STORE_MODULE_NAME].respData
        : {}
    },
    departmentData() {
      return store.state[STORE_MODULE_NAME_DEPARTMENT].respData != null
        ? store.state[STORE_MODULE_NAME_DEPARTMENT].respData.data.map(e => ({ _id: e._id, name: this.showFromCurrentLanguage(e.name) }))
        : []
    },
    agencyData() {
      return store.state[STORE_MODULE_NAME_AGENCY].respData != null
        ? store.state[STORE_MODULE_NAME_AGENCY].respData.data.map(e => ({ _id: e._id, name: this.showFromCurrentLanguage(e.name) }))
        : []
    },
    OptionServiceCharacteristics() {
      return [
        { name: this.$t('In The Radius'.toUpperCase()), value: 'in_the_radius' },
        { name: this.$t('In The Area'.toUpperCase()), value: 'in_the_area' },
      ]
    },
  },
  created() {
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, storeModule)
    if (!store.hasModule(STORE_MODULE_NAME_DEPARTMENT)) store.registerModule(STORE_MODULE_NAME_DEPARTMENT, storeModuleDepartment)
    if (!store.hasModule(STORE_MODULE_NAME_AGENCY)) store.registerModule(STORE_MODULE_NAME_AGENCY, storeModuleAgency)
    this.getDashboard()
    this.getAgency()
    this.getDepartment()
  },
  destroyed() {
    if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    if (store.hasModule(STORE_MODULE_NAME_DEPARTMENT)) store.unregisterModule(STORE_MODULE_NAME_DEPARTMENT)
    if (store.hasModule(STORE_MODULE_NAME_AGENCY)) store.unregisterModule(STORE_MODULE_NAME_AGENCY)
  },
  setup() {
    return { STORE_MODULE_NAME_DEPARTMENT, STORE_MODULE_NAME_AGENCY }
  },
  methods: {
    getDashboard() {
      const startDate = this.start.split('-')
      const endDate = this.end.split('-')
      const obj = {
        // eslint-disable-next-line radix
        start: this.formatDate(new Date(parseInt(startDate[2]), parseInt(startDate[1]) - 1, parseInt(startDate[0]))),
        // eslint-disable-next-line radix
        end: this.formatDate(new Date(parseInt(endDate[2]), parseInt(endDate[1]) - 1, parseInt(endDate[0]))),
        agencyId: this.agencyId !== null ? this.agencyId : '',
        departmentId: this.departmentId !== null ? this.departmentId : '',
      }
      this.show = true
      store
        .dispatch(`${STORE_MODULE_NAME}/get`, obj)
        .then(result => {
          this.show = false
          console.log('fetch Success : ', result)
          // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        })
        .catch(error => {
          this.show = false
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    getDepartment() {
      const obj = {
        currentPage: 0,
        pageSize: '0',
        searchQuery: '',
      }
      this.show = true
      store
        .dispatch(`${STORE_MODULE_NAME_DEPARTMENT}/get`, obj)
        .then(result => {
          this.show = false
          console.log('fetch Success : ', result)
          // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        })
        .catch(error => {
          this.show = false
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    getAgency() {
      const obj = {
        currentPage: 0,
        pageSize: '0',
        searchQuery: '',
      }
      this.show = true
      store
        .dispatch(`${STORE_MODULE_NAME_AGENCY}/get`, obj)
        .then(result => {
          this.show = false
          console.log('fetch Success : ', result)
          // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        })
        .catch(error => {
          this.show = false
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    showFromCurrentLanguage(data) {
      const indexLang = data.findIndex(e => e.lang === this.$i18n.locale)
      if (indexLang > -1) {
        return data[indexLang].value
      }
      return ''
    },
    padTo2Digits(num) {
      return num.toString().padStart(2, '0')
    },
    formatDate(date) {
      return [
        this.padTo2Digits(date.getDate()),
        this.padTo2Digits(date.getMonth() + 1),
        date.getFullYear(),
      ].join('-')
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
</style>
