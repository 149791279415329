<template>
  <b-card no-body>
    <b-card-header>
      <!-- title -->
      <b-card-title>{{ $t('Time for consultation') }}</b-card-title>
      <!--/ dropdown -->
    </b-card-header>

    <b-card-body>
      <vue-apex-charts type="heatmap" height="350" :options="chartOptions" :series="series" />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCardTitle,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import apexChatData from './apexChartData'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    VueApexCharts,
    BCardTitle,
  },
  data() {
    return {
      apexChatData,
      chartOptions: {
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: true,
          position: 'bottom',
        },
        colors: ['#7367F0'],
        plotOptions: {
          heatmap: {
            enableShades: false,
            colorScale: {
              ranges: [
                {
                  from: 0,
                  to: 50,
                  name: '0-50',
                  color: '#E0C9FA',
                },
                {
                  from: 51,
                  to: 100,
                  name: '51-100',
                  color: '#D2BAEC',
                },
                {
                  from: 101,
                  to: 150,
                  name: '101-150',
                  color: '#B789E9',
                },
                {
                  from: 151,
                  to: 200,
                  name: '151-200',
                  color: '#60269E',
                },
              ],
            },
          },
        },
        xaxis: {
          labels: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        chart: {
          height: 350,
          type: 'heatmap',
          toolbar: {
            show: false,
          },
        },
      },

    }
  },
  computed: {
    series() {
      return [
        {
          name: this.$t('Sunday'),
          data: this.generateDataHeatMap(24, {
            min: 0,
            max: 200,
          }),
        },
        {
          name: this.$t('Monday'),
          data: this.generateDataHeatMap(24, {
            min: 0,
            max: 200,
          }),
        },
        {
          name: this.$t('Tuesday'),
          data: this.generateDataHeatMap(24, {
            min: 0,
            max: 200,
          }),
        },
        {
          name: this.$t('Wednesday'),
          data: this.generateDataHeatMap(24, {
            min: 0,
            max: 200,
          }),
        },
        {
          name: this.$t('Thursday'),
          data: this.generateDataHeatMap(24, {
            min: 0,
            max: 200,
          }),
        },
        {
          name: this.$t('Friday'),
          data: this.generateDataHeatMap(24, {
            min: 0,
            max: 200,
          }),
        },
        {
          name: this.$t('Saturday'),
          data: this.generateDataHeatMap(24, {
            min: 0,
            max: 200,
          }),
        },
      ]
    },
  },
  methods: {
    generateDataHeatMap(count, yrange) {
      let i = 0
      const series = []
      while (i < count) {
        const x = `w${(i + 1).toString()}`
        const y = Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min

        series.push({
          x,
          y,
        })
        // eslint-disable-next-line no-plusplus
        i++
      }
      return series
    },
  },
}
</script>
